<template>
  <div>
    <section class="" :class="{'p-0': modal}">
      <card-component :title="$route.params.id ? `Manuel İşlem Düzenle` : 'Manuel İşlem Oluştur'" icon="domain">
        <form @submit.prevent="save">
          <b-field grouped group-multiline>
            <b-field expanded label="Site" v-if="sites.length > 0">
              <b-select size="is-small" v-model="form.siteId" expanded required>
                <option :value="null">-</option>
                <option :value="item.uuid" v-for="item in sites">{{item.name}}</option>
              </b-select>
            </b-field>
            <b-field expanded label="Tür">
              <b-select size="is-small" v-model="form.type" expanded>
                <option value="WITHDRAW">Çekim</option>
                <option value="DEPOSIT">Yatırım</option>
              </b-select>
            </b-field>
          </b-field>
          <hr/>
          <b-field grouped group-multiline>
            <b-field expanded label="Kasa">
              <b-select size="is-small" v-model="form.bankType" expanded>
                <option :value="key" v-for="(item, key) in enums.bankTypes">{{item}}</option>
              </b-select>
            </b-field>
            <b-field expanded label="Para Birimi">
              <b-select size="is-small" v-model="form.currencyId" expanded>
                <option :value="null">-</option>
                <option :value="item.uuid" v-for="item in currencies">{{ item.shortName }}</option>
              </b-select>
            </b-field>
          </b-field>
          <hr/>
          <b-field grouped group-multiline>
            <b-field expanded label="Miktar">
              <b-input size="is-small" v-model="form.total" placeholder="Miktar" name="total" required/>
            </b-field>
            <b-field expanded label="Karşılık Miktarı(₺)">
              <b-input size="is-small" v-model="form.totalTo" placeholder="Karşılık MiktarıMiktar" name="totalTo"
                       required/>
            </b-field>

          </b-field>
          <hr/>
          <b-field grouped group-multiline>
            <b-field expanded label="Komisyon Oranı(%)">
              <b-input size="is-small" v-model="form.commissionRate" placeholder="Komisyon Oranı(%)"
                       name="commissionRate"
                       required/>
            </b-field>
            <b-field expanded label="Komisyon(₺)">
              <b-input size="is-small" v-model="form.commission" placeholder="Komisyon(₺)" name="commission" required/>
            </b-field>
          </b-field>
          <hr/>
          <b-field grouped group-multiline>
            <b-field expanded label="İşlem Kodu">
              <b-input size="is-small" v-model="form.code" placeholder="İşlem Kodu" name="code"/>
            </b-field>
            <b-field expanded label="Açıklama">
              <b-input size="is-small" v-model="form.description" placeholder="Açıklama" name="description"/>
            </b-field>
          </b-field>
          <hr/>
          <div class="is-flex is-justify-content-end is-flex-grow-1">
            <div class="control mr-2">
              <b-button native-type="submit" type="is-primary" size="is-small">
                {{ $route.params.id ? 'Güncelle' : 'Ekle' }}
              </b-button>
            </div>
            <div class="control">
              <b-button type="is-primary is-outlined" size="is-small" @click="modal ? $emit('close') : $router.go(-1)">
                Geri Dön
              </b-button>
            </div>
          </div>
        </form>
      </card-component>
    </section>
  </div>
</template>

<script>
import mapValues from 'lodash/mapValues'
import CardComponent from '@/components/CardComponent'
import { ManualTransactionService as Service, CurrencyService, SiteService } from '@/services'

export default {
  name: 'ManualTransactionCreateOrUpdate',
  components: { CardComponent },
  props: ['modal', 'reportData', 'id', 'siteId'],
  data () {
    return {
      isLoading: false,
      form: {
        total: null,
        currencyId: null,
        totalTo: null,
        commission: null,
        commissionRate: null,
        siteId: null,
        description: null,
        type: null,
        code: null,
        bankType: 'PAPARA'
      },
      currencies: [],
      sites: []
    }
  },
  mounted () {
    if (this.reportData) this.form.siteId = this.reportData.SiteId
    this.form.siteId = this.siteId
    this.getCurrencies()
    if (!this.form.siteId) this.getSites()
    if (this.id) this.getData()
  },
  methods: {
    getSites(){
      SiteService.all({status: 1}).then(({data}) => this.sites = data)
    },
    getCurrencies () {
      CurrencyService.all().then(({ data }) => this.currencies = data)
    },
    getData () {
      Service.edit(this.id).then(({ data }) => this.form = data)
          .catch(err => this.$buefy.toast.open({ message: err.message, position: 'is-top-right', type: 'is-danger' }))
    },
    save () {
      Service[this.id ? 'update' : 'create'](this.form)
          .then(() => {
            this.$buefy.toast.open({
              message: this.id ? 'Güncellendi' : 'Eklendi',
              type: 'is-success'
            })
            this.reset()
            if (this.modal)
              this.$emit('close')
            else
              this.$router.go(-1)
          })
          .catch(err => this.$buefy.toast.open({ message: err.message, position: 'is-top-right', type: 'is-danger' }))
    },
    reset () {
      this.form = mapValues(this.form, (item) => {
        if (item && typeof item === 'object') {
          return []
        }
        return null
      })
    }
  },
  watch: {
    'form.commissionRate': function(){
      this.form.commission = (this.form.totalTo * this.form.commissionRate) / 100
    },
    'form.totalTo': function(){
      this.form.commission = (this.form.totalTo * this.form.commissionRate) / 100
    },
    'form.total': function(){
      this.form.commission = (this.form.totalTo * this.form.commissionRate) / 100
    }
  }
}
</script>
